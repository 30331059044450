import type {
  Blog,
  Board,
  Category,
  Community,
  Forum,
  GroupHub,
  Tkb,
  Idea,
  Occasion
} from '@aurora/shared-generated/types/graphql-schema-types';
import { ConversationStyle } from '@aurora/shared-generated/types/graphql-schema-types';
import { NodeType } from '@aurora/shared-types/nodes/enums';

/**
 * User defined type guard for working with Board nodes
 *
 * @param node the node
 */
export function isNodeBoard(node): node is Board {
  return node?.nodeType === NodeType.BOARD;
}

/**
 * User defined type guard for working with Blog nodes
 *
 * @param node the node
 */
export function isNodeBlog(node): node is Blog {
  return isNodeBoard(node) && node.conversationStyle === ConversationStyle.Blog;
}

/**
 * User defined type guard for working with Forum nodes
 *
 * @param node the node
 */
export function isNodeForum(node): node is Forum {
  return isNodeBoard(node) && node.conversationStyle === ConversationStyle.Forum;
}

/**
 * User defined type guard for working with Idea nodes
 *
 * @param node the node
 */
export function isNodeIdea(node): node is Idea {
  return isNodeBoard(node) && node.conversationStyle === ConversationStyle.Idea;
}

/**
 * User defined type guard for working with Tkb nodes
 *
 * @param node the node
 */
export function isNodeTkb(node): node is Tkb {
  return isNodeBoard(node) && node.conversationStyle === ConversationStyle.Tkb;
}

/**
 * User defined type guard for working with GroupHub nodes
 *
 * @param node the node
 */
export function isNodeGroupHub(node): node is GroupHub {
  return node.nodeType === NodeType.GROUPHUB;
}

/**
 * User defined type guard for working with Community node
 *
 * @param node the node
 */
export function isNodeCommunity(node): node is Community {
  return node.nodeType === NodeType.COMMUNITY;
}

/**
 * User defined type guard for working with Category nodes
 *
 * @param node the node
 */
export function isNodeCategory(node): node is Category {
  return node.nodeType === NodeType.CATEGORY;
}

/**
 * User defined type guard for working with Occasion nodes
 *
 * @param node the node
 */
export function isNodeOccasion(node): node is Occasion {
  return isNodeBoard(node) && node.conversationStyle === ConversationStyle.Occasion;
}

/**
 * Function to check if the node is container node or not
 *
 * @param nodeType the node type
 */
export function isContainerNode(nodeType: NodeType): boolean {
  const containerNode = [NodeType.COMMUNITY, NodeType.CATEGORY, NodeType.GROUPHUB];
  return containerNode.includes(nodeType);
}
