import Button from '@aurora/shared-client/components/common/Button/Button';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import { dropdownPopperConfig } from '@aurora/shared-client/helpers/ui/PopperJsHelper';
import { OccasionType } from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React, { useState } from 'react';
import { Dropdown, useClassNameMapper } from 'react-bootstrap';
import useTranslation from '../../useTranslation';
import OccasionTypeFilterDropdownItem from './OccasionTypeFilterDropdownItem';
import type { OccasionTypeDropdownItem } from './types';

interface Props {
  /**
   * Callback function when a status filter is selected.
   *
   * @callback
   */
  onChange?: (item) => void;
  /**
   * The selected occasion status
   */
  selectedStatus: string;
  /**
   * The occasion status tab
   */
  occasionStatusTab: string;
}

/**
 * Occasion status filter bar dropdown.
 *
 * @author Arjun Krishna
 */

const OccasionStatusFilterDropdown: React.FC<Props> = ({
  onChange,
  selectedStatus = 'ALL',
  occasionStatusTab
}) => {
  const { formatMessage } = useTranslation(EndUserComponent.OCCASION_STATUS_FILTER);
  const cx = useClassNameMapper();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const occasionStatusList: OccasionTypeDropdownItem[] = [
    {
      statusKey: 'ALL',
      text: formatMessage('ALL')
    },
    {
      statusKey: OccasionType.Live,
      text: formatMessage(OccasionType.Live)
    },
    {
      statusKey: OccasionType.InPerson,
      text: formatMessage(OccasionType.InPerson)
    },
    {
      statusKey: OccasionType.Hybrid,
      text: formatMessage(OccasionType.Hybrid)
    }
  ];

  /**
   * Render the status filter dropdown.
   */
  function renderStatusDropdown(): React.ReactElement {
    return (
      <Dropdown
        className={'lia-g-py-5'}
        data-testid="OccasionStatusFilter.Dropdown"
        onToggle={isOpen => {
          setDropdownOpen(isOpen);
        }}
        data-menu-open={dropdownOpen}
      >
        <Dropdown.Toggle
          as={Button}
          variant={ButtonVariant.LINK}
          size="sm"
          className={cx('lia-g-dropdown-toggle')}
        >
          {formatMessage(selectedStatus)}
        </Dropdown.Toggle>
        <Dropdown.Menu align="right" popperConfig={dropdownPopperConfig}>
          {occasionStatusList.map(item => {
            return (
              <OccasionTypeFilterDropdownItem
                item={item}
                onOccasionTypeSelected={(): void => onChange(item)}
                active={item.statusKey === selectedStatus}
                key={item.statusKey}
                occasionStatusTab={occasionStatusTab}
              />
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  return <>{renderStatusDropdown()}</>;
};

export default OccasionStatusFilterDropdown;
